import { ApiClient } from "./api-client";
import { AppException } from "../exceptions/exceptions";
import { CommonErrorCodes } from "../errors/common-error-codes";
import {
  IDetailedSubmission,
  IStandards,
  ISubmission,
} from "../types/standard";
import {
  IActionItems,
  IFormWithQuestions,
  IVerifiedActionItems,
  IVerifyRequest,
} from "../types/action-item";
import { hasProps } from "../utils/util";

export const submitForm = async (
  submission: IDetailedSubmission,
): Promise<ISubmission> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.post("/submissions", submission);
    return response.data as ISubmission;
  } catch (e) {
    throw new AppException(
      "Error submitting the module form. Please try again.",
      CommonErrorCodes.API_ERROR,
    );
  }
};

export const getStandards = async (): Promise<IStandards[]> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.get("/standards");
    return response.data as IStandards[];
  } catch (e) {
    console.error("An error happened", e);
    throw new AppException(
      "There was an error fetching the standards. Please refresh the page.",
      CommonErrorCodes.API_ERROR,
    );
  }
};

type UpdatedSubmissionsResponse = {
  updatedSubmissions: number;
};
export const updateIncompleteStandards =
  async (): Promise<UpdatedSubmissionsResponse> => {
    const client = ApiClient.getInstance();
    try {
      const response = await client.post("/submissions/update-incomplete");
      return response.data as UpdatedSubmissionsResponse;
    } catch (e) {
      console.error("An error happened", e);
      throw new AppException(
        "There was an error updating the incomplete standards. Please refresh the page.",
        CommonErrorCodes.API_ERROR,
      );
    }
  };

export const getScore = async (standardId: number): Promise<IStandards> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.get(`/standards/${standardId}`);
    return response.data as IStandards;
  } catch (e) {
    console.error("An error happened", e);
    throw new AppException(
      "There was an error fetching the standard. Please refresh the page.",
      CommonErrorCodes.API_ERROR,
    );
  }
};

export const getActionItems = async (
  standardId: number,
): Promise<IActionItems[]> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.get(`/standards/${standardId}/action-items`);
    return response.data as IActionItems[];
  } catch (e) {
    console.error("An error happened", e);
    throw new AppException(
      "There was an error fetching the action items. Please refresh the page.",
      CommonErrorCodes.API_ERROR,
    );
  }
};

export const getQuestions = async (
  standardId: number,
): Promise<IFormWithQuestions> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.get(`/standards/${standardId}/full`);
    return response.data! as IFormWithQuestions;
  } catch (e) {
    console.error("An error happened", e);
    throw new AppException(
      "There was an error fetching the questions. Please refresh the page.",
      CommonErrorCodes.API_ERROR,
    );
  }
};

export const markActionItem = async (
  actionItemId: number,
  isDone: boolean,
): Promise<IActionItems> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.put(`/action-items/${actionItemId}`, {
      done: isDone,
    });
    return response.data as IActionItems;
  } catch (e) {
    if (hasProps(e, "code")) {
      if (e.code === "404")
        throw new AppException(
          "The action items sent for verification cannot be changed.",
          CommonErrorCodes.API_ERROR,
        );
    }
    throw new AppException(
      "There was an error marking the action. Please try again.",
      CommonErrorCodes.API_ERROR,
    );
  }
};

export const verifyActionItems = async (
  standardId: number,
): Promise<IVerifiedActionItems> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.post("/verification-requests", {
      standardId: standardId,
    });
    return response.data as IVerifiedActionItems;
  } catch (e: unknown) {
    if (hasProps(e, "code")) {
      if (e.code === "404")
        throw new AppException(
          "There are no action items to be verified.",
          CommonErrorCodes.API_ERROR,
        );
    }

    throw new AppException(
      "There was an error sending the request for verification. Please try again.",
      CommonErrorCodes.API_ERROR,
    );
  }
};

export const verifyRequestAdmin = async (
  location: string,
): Promise<IVerifyRequest> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.get(location);
    return response.data as IVerifyRequest;
  } catch (e: unknown) {
    throw new AppException(
      "There was an error verifying the request. Please try again.",
      CommonErrorCodes.API_ERROR,
    );
  }
};
