import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { QuestionnaireHeader } from "../../components/QuestionnaireHeader";
import { getQuestions, submitForm } from "../../services/standards-service";
import { AppError, Paths } from "../../types/app";
import { useLocation, useNavigate } from "react-router";
import { IStandards } from "../../types/standard";
import { useError } from "../../contexts/ErrorContext";
import { useEffect, useState } from "react";
import { IQuestion } from "../../types/action-item";

export type IQuestionResponse = {
  externalQuestionId: string;
  responseText: string;
  externalQuestionRef?: string;
};

export type IQuestionResponses = Record<number, IQuestionResponse>;

export default function Questionnaire() {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleError } = useError();
  const { standard } =
    (location.state as {
      standard: IStandards;
    }) || {};

  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [responses, setResponses] = useState<IQuestionResponses>({});

  const submitStandard = async (responses: IQuestionResponses) => {
    const formattedResponses = Object.values(responses);

    try {
      await submitForm({
        standardId: standard.id,
        externalResponseId: Math.random().toString(36).slice(2),
        responses: formattedResponses,
      });
      navigate(Paths.ACTION_PLAN_HOME_PAGE);
    } catch (e) {
      const error = e as AppError;
      if (error && error.message) {
        handleError(error.message);
      }
    }
  };

  const recordResponse = (
    id: number,
    response: string,
    externalQuestionId: string,
    questionRef: string,
  ) => {
    setResponses({
      ...responses,
      [id]: {
        externalQuestionId: externalQuestionId,
        responseText: response,
        externalQuestionRef: questionRef,
      },
    });
  };

  useEffect(() => {
    if (standard) {
      getQuestions(standard?.id).then((res) =>
        setQuestions(res.form.questions),
      );
    } else {
      navigate(Paths.ACTION_PLAN_HOME_PAGE);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        background: "#F7F7F7",
      }}
    >
      {standard && (
        <>
          <QuestionnaireHeader standardName={standard.title} />
          <Grid container>
            <Grid
              item
              xs={9}
              sx={{
                margin: "0 auto",
              }}
            >
              <Box
                sx={{
                  my: { xl: 17, l: 17, md: 17, sm: 15, xs: 10 },
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="h1"
                  variant="h4"
                  sx={{ fontWeight: 500, mb: 2 }}
                >
                  Self Assessment
                </Typography>
                <Typography gutterBottom>
                  Please self-assess your organisation's performance against
                  each of the standards.
                </Typography>

                <Box
                  component="form"
                  noValidate
                  // onSubmit={handleSubmit}
                  sx={{
                    mt: { xl: 4, sm: 1 },
                    width: { xl: 480, lg: 480, md: 480, sm: 450, xs: 345 },
                  }}
                >
                  <Typography variant="body1">
                    <ol>
                      {questions.map((q) => (
                        <>
                          <li>
                            {q.questionText}
                            {q.questionDescription && (
                              <Tooltip title={q.questionDescription} arrow>
                                <InfoIcon
                                  color="action"
                                  fontSize="small"
                                  sx={{ ml: 1, fontSize: "16px" }}
                                />
                              </Tooltip>
                            )}
                          </li>
                          <Button
                            variant={
                              responses[q.id]?.responseText === "true"
                                ? "contained"
                                : "outlined"
                            }
                            sx={{
                              ...{
                                mr: 3,
                                mt: 2,
                                mb: 6,
                                borderColor: "#FFC164",
                                color: "#000000",
                              },
                              ...(responses[q.id]?.responseText === "true" && {
                                backgroundColor: "#FFC164",
                                fontWeight: "600",
                              }),
                            }}
                            onClick={() =>
                              recordResponse(
                                q.id,
                                "true",
                                q.externalQuestionId,
                                q.externalQuestionRef,
                              )
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            variant={
                              responses[q.id]?.responseText === "false"
                                ? "contained"
                                : "outlined"
                            }
                            sx={{
                              ...{
                                mr: 3,
                                mt: 2,
                                mb: 6,
                                borderColor: "#FFC164",
                                color: "#000000",
                              },
                              ...(responses[q.id]?.responseText === "false" && {
                                backgroundColor: "#FFC164",
                                fontWeight: "600",
                              }),
                            }}
                            onClick={() =>
                              recordResponse(
                                q.id,
                                "false",
                                q.externalQuestionId,
                                q.externalQuestionRef,
                              )
                            }
                          >
                            No
                          </Button>
                        </>
                      ))}
                    </ol>
                  </Typography>

                  <Grid container spacing={3}>
                    <Grid container item xs={6}></Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    role="button"
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      borderRadius: "25px",
                      fontSize: "16px",
                      pt: 1.5,
                      pb: 1.5,
                    }}
                    onClick={() => submitStandard(responses)}
                  >
                    Save responses for {standard.title}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
